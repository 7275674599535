import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js"

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>

<MDXTag name="p" components={components}>{`I recently came across `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://svelte.dev/"}}>{`Svelte`}</MDXTag>{`, which
claims to be a new approach to UI development. It `}<MDXTag name="em" components={components} parentName="p">{`compiles`}</MDXTag>{`
your code into plain JavaScript and doesn’t require a runtime.
Well, this is not entirely true because the runtime is now
scattered across your various components, each containing just
the parts of the runtime that it needs.`}</MDXTag>
<MDXTag name="p" components={components}>{`Frameworks like `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://reactjs.org/"}}>{`React`}</MDXTag>{`, on the other hand,
use a common runtime layer that performs the state updates.
What this means is that you have to bundle in React with your
application (which means a non-trivial bundle size increase,
especially for small apps).`}</MDXTag>
<MDXTag name="p" components={components}>{`Svelte seems quite interesting on its own, but it feels
like a particularly good fit for a library. One of my projects
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://boardgame.io/"}}>{`boardgame.io`}</MDXTag>{`, for example, is a library
that manages state for turn-based games. It also comes with an
integration for React, and bundles in some React components
that developers can use.`}</MDXTag>
<MDXTag name="p" components={components}>{`While the React integration is strong, I never meant for
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://boardgame.io/"}}>{`boardgame.io`}</MDXTag>{` to be a React library.
In fact, the React integration is just a thin wrapper around
the plain JavaScript client, which can be used on its own.
However, there are a few challenges that make the vanilla JS
experience less optimal than the React experience.`}</MDXTag>
<MDXTag name="p" components={components}>{`For example, there is a Debugging Panel (written in React)
that React clients have access to. There are also various
game components like cards and tokens that are written in React.
In theory I could bundle in React itself to make these things
available to the plain JavaScript users, but this is not ideal.`}</MDXTag>
<MDXTag name="p" components={components}>{`In order for the library to be truly React agnostic, I’d
either have to distribute different versions of these components,
one for each popular UI framework, or go the
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.webcomponents.org/introduction"}}>{`Web Components`}</MDXTag>{` route.`}</MDXTag>
<MDXTag name="p" components={components}>{`Svelte seems like the best of all worlds here. You can compile
your components to Web Components, or use them as plain
JavaScript classes in environments that don’t support
Web Components.`}</MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  